type AuthModuleType = {
  ACCOUNTS: string[]
  CASES: string[]
  CASES_UPDATE: string[]
  CHANGES: string[]
  CONFIGURATION_ITEMS: string[]
  CONTACTS: string[]
  HOME: string[]
  INCIDENTS: string[]
  NOT_PERMITTED: string[]
  NRQL: string[]
  PROBLEMS: string[]
  REPORTS: string[]
  SERVICENOW_ACCOUNTS: string[]
  SERVICENOW_ACCOUNTS_UPDATE: string[]
}

const CI_CSV_MUTATION_ROLES = [
  'role-cog-be-customer',
  'role-cog-nl-customer',
  'role-sg-my-mutation_configuration_items_report',
]
const CONTACTS_CSV_MUTATION_ROLES = [
  'role-cog-be-customer',
  'role-cog-nl-customer',
  'role-sg-my-mutation_contacts_report',
]

const AUTH_MODULE: AuthModuleType = {
  ACCOUNTS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_dynamics365_accounts',
  ],
  CASES: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_cases',
  ],
  CASES_UPDATE: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-mutation_servicenow_cases',
  ],
  CHANGES: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_cases',
  ],
  CONFIGURATION_ITEMS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_configuration_items',
    'role-sg-my-mutation_configuration_items_report',
  ],
  CONTACTS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_dynamics365_contacts',
    'role-sg-my-mutation_contacts_report',
  ],
  HOME: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_dynamics365_accounts',
  ],
  INCIDENTS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_cases',
  ],
  NOT_PERMITTED: [],
  NRQL: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_newrelic_nrql',
  ],
  PROBLEMS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_cases',
  ],
  REPORTS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_power_bi_reports',
  ],
  SERVICENOW_ACCOUNTS: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-query_servicenow_accounts',
  ],
  SERVICENOW_ACCOUNTS_UPDATE: [
    'role-cog-be-customer',
    'role-cog-nl-customer',
    'role-sg-my-mutation_servicenow_accounts',
  ],
}

export { AUTH_MODULE, CI_CSV_MUTATION_ROLES, CONTACTS_CSV_MUTATION_ROLES }
